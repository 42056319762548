<template>
  <n-auth-panel>
    <template v-slot:heading>
      <n-html :text="$t('auth.login.title')" />
    </template>

    <template v-slot:content>
      <n-html :text="$t('auth.login.subtitle')" />
    </template>

    <div class="flex flex-row justify-end">
      <a
        class="text-xs text-brand-purple-500 underline cursor-pointer"
        @click="passwordless_login = !passwordless_login"
      >
        <n-html
          :text="
            passwordless_login
              ? $t('auth.login.deactivate_passwordless_login')
              : $t('auth.login.activate_passwordless_login')
          "
        />
      </a>
    </div>

    <div class="space-y-4 mt-4">
      <n-form-group
        id="input-group-email"
        label-for="input-email"
        :label="$t('form.email.label')"
        :invalid-feedback="$t('form.email.feedback.invalid')"
        :state="form_states.email"
      >
        <n-form-input-default
          id="input-email"
          :placeholder="$t('form.email.placeholder')"
          v-model="email"
          type="email"
        />
      </n-form-group>

      <n-form-group
        v-show="!passwordless_login"
        id="input-group-password"
        :label="$t('form.password.label')"
        label-for="input-password"
        :invalid-feedback="$t('form.password.feedback.invalid')"
        :state="form_states.password"
      >
        <n-form-input-password
          id="input-password"
          :placeholder="$t('form.password.placeholder')"
          v-model="password"
          v-on:keyup.enter.native="login"
        />
      </n-form-group>
    </div>

    <div class="flex flex-col font-base text-xs space-y-2">
      <p v-show="has_error" class="text-brand-red-500">
        <n-html :text="getErrorMessage()" />
      </p>
      <p v-show="send_email" class="">
        <n-html :text="$t('auth.login.sent_login_link')" />
      </p>
    </div>

    <n-form-group
      class="mt-11"
      id="button-group-login"
      :invalid-feedback="$t('auth.login.error')"
      :state="form_states.login"
    >
      <n-button-regular full-width @click.native="login">
        <n-html :text="$t('auth.login.button')" />
      </n-button-regular>
    </n-form-group>

    <div class="flex flex-row justify-between mt-4">
      <a
        class="text-xs text-brand-purple-500 underline cursor-pointer"
        @click="$router.push({ name: 'auth.register' })"
      >
        <n-html :text="$t('auth.login.register')" />
      </a>

      <a
        class="text-xs text-brand-purple-500 underline cursor-pointer"
        @click="$router.push({ name: 'auth.forgot_password' })"
      >
        <n-html :text="$t('auth.login.password_forgot')" />
      </a>
    </div>
  </n-auth-panel>
</template>

<script>
import validation from "../../mixins/validation";

export default {
  name: "pages.auth.login",
  mixin: [validation],
  data: () => ({
    email: "",
    password: "",
    passwordless_login: true,
    send_email: false,
    form_states: {
      email: null,
      password: null,
      login: null
    },
    has_error: false,
    errors: {
      error: false,
      password_expired: false,
      passwordless_error: false,
      no_user_found: false,
      incorrect_login: false,
      incorrect_passwordless_email: false,
      invalid_token: false
    }
  }),
  computed: {
    customToken() {
      return this.$route.query.token;
    },
    valid() {
      return (
        !!(this.email && this.password) & this.validateEmail(this.email) &&
        this.validatePassword(this.password)
      );
    }
  },
  created() {
    if (this.customToken) {
      this.loginWithCustomToken();
    }

    if (this.$firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.loginWithEmailLink();
    }
  },
  methods: {
    resetErrors() {
      this.errors.error = false;
      this.errors.password_expired = false;
      this.errors.passwordless_error = false;
      this.errors.no_user_found = false;
      this.errors.incorrect_login = false;
      this.errors.incorrect_passwordless_email = false;
      this.errors.invalid_token = false;
    },
    checkUserExists() {
      this.$store
        .dispatch("application/userExists", this.email)
        .then(response => {
          if (response.data.allowed) {
            this.errors.password_expired = true;
          } else {
            this.form_states.login = false;
            this.errors.incorrect_login = true;
          }
        })
        .catch(() => {
          this.errors.error = true;
        });
    },
    async login() {
      if (this.passwordless_login) {
        if (this.$firebase.auth().isSignInWithEmailLink(window.location.href)) {
          if (this.errors.invalid_token) {
            this.resetErrors();
            await this.sendLoginLink();
            return;
          }
          if (this.errors.incorrect_passwordless_email) {
            window.localStorage.setItem("passwordless_login_email", this.email);
          }
          await this.loginWithEmailLink();
        } else {
          this.resetErrors();
          await this.sendLoginLink();
        }
      } else {
        this.resetErrors();
        await this.loginWithEmailAndPassword();
      }
    },
    async loginWithEmailAndPassword() {
      try {
        await this.$firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
      } catch (err) {
        if (err.code === "auth/wrong-password") {
          this.checkUserExists();
          // this.form_states.login = false;
        } else {
          this.checkUserExists();
          this.form_states.login = false;
          console.error(err);
        }
      }
    },
    async sendLoginLink() {
      this.$store
        .dispatch("application/userExists", this.email)
        .then(response => {
          if (response.data.found) {
            this.$store
              .dispatch("application/sendSignInLinkToEmail", this.email)
              .then(() => {
                this.resetErrors();
                window.localStorage.setItem(
                  "passwordless_login_email",
                  this.email
                );
                this.send_email = true;
              })
              .catch(err => {
                if (err.response && err.response.status === 404) {
                  this.errors.no_user_found = true;
                } else {
                  this.errors.error = true;
                }
              });
          } else {
            this.errors.incorrect_login = true;
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            this.errors.no_user_found = true;
          } else {
            this.errors.error = true;
          }
        });
    },
    async loginWithEmailLink() {
      let email = window.localStorage.getItem("passwordless_login_email");
      if (!email) {
        this.errors.incorrect_passwordless_email = true;
        return;
      }

      this.$firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(result => {
          this.$store
            .dispatch("user/activate_after_sign_in_link", {
              uid: result.user.uid
            })
            .then(() => {
              window.localStorage.removeItem("passwordless_login_email");
            });
        })
        .catch(error => {
          if (error.code === "auth/invalid-action-code") {
            this.errors.invalid_token = true;
          } else {
            this.errors.passwordless_error = true;
          }
        });
    },
    async loginWithCustomToken() {
      try {
        await this.$firebase.auth().signInWithCustomToken(this.customToken);
      } catch (err) {
        this.form_states.login = false;
        console.error(err);
      }
    },
    getErrorMessage() {
      if (this.errors.password_expired) {
        return this.$t("errors.login.password_expired");
      } else if (this.errors.invalid_token) {
        return this.$t("errors.login.invalid_token");
      } else if (this.errors.incorrect_login) {
        return this.$t("errors.login.incorrect_login");
      } else if (this.errors.incorrect_passwordless_email) {
        return this.$t("errors.login.incorrect_passwordless_email");
      } else if (this.errors.no_user_found) {
        return this.$t("errors.login.no_user_found");
      }

      return this.$t("errors.login.error");
    }
  },
  watch: {
    email(n) {
      this.form_states.email = n && this.validateEmail(n);
    },
    password(n) {
      this.form_states.password = n && this.validatePassword(n);
    },
    errors: {
      handler(n) {
        this.has_error = Object.values(n).some(value => value === true);
      },
      deep: true
    }
  }
};
</script>
